<!--
  Typically used within a Form to trigger the onSubmit handler.
-->
<script setup lang="ts">import { computed as _computed } from 'vue';
const explicitSize = __MACROS_toRef(__props, "size");

import { toRef as __MACROS_toRef } from "vue";
import type { ButtonHTMLAttributes } from 'vue'
import { preserveWidthWhile } from '@shared/composables'
import { useForm } from '@consumer/services/form'
import { iconSizeForButton } from '@consumer/logic/icons'
import type { ButtonSize } from './GButton.vue'

withDefaults(defineProps<{
  // The preferred way to specify the content when it's a String.
  label?: string

  // When loading, the button text is replaced with a loading indicator.
  loading?: boolean

  // The size of the input.
  size?: ButtonSize

  // By default the button triggers a submit event when used in Form.
  type?: ButtonHTMLAttributes['type']

  // Whether it's a tertiary button.
  tertiary?: boolean

  // Whether it's a secondary button.
  secondary?: boolean

  // Whether it's a gold button.
  color?: 'gold'
}>(), { label: 'Save',type: 'submit', })

const form = useForm()

const isSaving = _computed(() => form ? form.submitting.value : __props.loading)

// Inputs can inherit their size from the parent form.
const size = _computed(() => __props.size || (form?.size) || 'large')

// Preserve button width while displaying loading indicator.
const buttonRef = ref()
preserveWidthWhile(buttonRef, () => isSaving.value)

defineExpose({
  focus: () => buttonRef.value?.focus(),
  blur: () => buttonRef.value?.blur(),
  click: () => buttonRef.value?.click(),
})
</script>

<template>
  <GButton
    ref="buttonRef"
    class="save-button"
    :primary="!tertiary && !secondary"
    :tertiary="tertiary"
    :secondary="secondary"
    :color="color"
    :disabled="isSaving || form?.disableSubmit.value"
    :size="size"
    :type="type"
    :label="label"
  >
    <LoadingIndicator v-if="isSaving" :size="iconSizeForButton(size)"/>
    <slot v-else>{{ label }}</slot>
  </GButton>
</template>

<style scoped lang="scss">
.save-button {
  min-width: 132px;
}
</style>
